<script setup lang="ts">
  import Breadcrumbs from '~/UI/Breadcrumbs.vue';
  const { pageInfo } = storeToRefs(usePageInfoStore());
</script>

<template>
  <div>
    <Header />
    <div class="white-container">
      <SearchPanel class="layout-search-panel" />
      <div class="main-container">
        <Breadcrumbs :items="pageInfo?.breadcrumbs ?? []" />
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<style scoped lang="scss">
.layout-search-panel {
  padding-top: 8px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
.mb-6 {
  margin-bottom: 24px;
}
</style>
